import { Button } from 'components/Button'
import { EditIcon } from 'components/Icons'
import styles from './WithEditButton.module.css'

export const WithEditButton = ({ onClick, children }) => (
  <div className={styles.withEditButton}>
    {children}
    <div className={styles.editButtonContainer}>
      <Button onClick={onClick} small icon={<EditIcon />} filled>
        Aanpassen
      </Button>
    </div>
  </div>
)
