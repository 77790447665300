import { Button } from 'components/Button'
import { CloseIcon, DeleteIcon } from 'components/Icons'
import { Overlay } from 'components/Overlay'
import { RichText } from 'components/RichText'
import { staticText } from 'content/staticText'
import styles from './DeleteFileOverlay.module.css'

export const DeleteFileOverlay = ({ onClose, onTrash }) => (
  <Overlay onClose={onClose}>
    <RichText>
      <h2>{staticText.confirmDeleteTitle}</h2>
      <p>{staticText.confirmDeleteText}</p>
    </RichText>

    <div className={styles.buttonsContainer}>
      <Button icon={<DeleteIcon />} onClick={onTrash}>
        {staticText.delete}
      </Button>
      <Button icon={<CloseIcon />} onClick={onClose}>
        {staticText.cancel}
      </Button>
    </div>
  </Overlay>
)
