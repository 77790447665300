import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { MediaLibrary } from 'components/MediaLibrary'
import { Overlay } from 'components/Overlay'
import { AUTO, S, M, L, FULL } from 'shared/sizes'
import { InfoIcon, SaveIcon } from 'components/Icons'
import { Input } from 'components/Input'
import { Select } from 'components/Select'
import { staticText } from 'content/staticText'
import { useState } from 'react'
import { WithEditButton } from 'components/WithEditButton'
import styles from './ImageOverlay.module.css'

export const ImageOverlay = ({ content, disableCaption, disableSize, info, onClose, onSave }) => {
  const [localAlt, setLocalAlt] = useState(content?.alt || '')
  const [localCaption, setLocalCaption] = useState(content?.caption || '')
  const [localImage, setLocalImage] = useState(content || { src: '/placeholder.jpg' })
  const [localSize, setLocalSize] = useState(content?.size || (disableSize ? null : S))
  const [showMediaLibrary, setShowMediaLibrary] = useState(false)

  const sizeOptions = [
    { value: AUTO, label: staticText.smallAuto },
    { value: S, label: staticText.small },
    { value: M, label: staticText.medium },
    { value: L, label: staticText.large },
    { value: FULL, label: staticText.fullWidth },
  ]

  const onMediaUse = (v) => {
    setLocalImage(v)
    setShowMediaLibrary(false)
  }

  const onSaveClick = () => onSave({ ...localImage, size: localSize, alt: localAlt, caption: localCaption })

  return (
    <Overlay onClose={onClose}>
      {showMediaLibrary ? (
        <MediaLibrary filterFn={({ contentType }) => contentType.indexOf('image') > -1} onUse={onMediaUse} />
      ) : (
        <>
          <div className={styles.group}>
            <WithEditButton onClick={() => setShowMediaLibrary(true)}>
              <div style={{ minHeight: 50 }}>
                <Image content={{ ...localImage, size: localSize }} />
              </div>
            </WithEditButton>
            {info && (
              <div className={styles.imageInfo}>
                <InfoIcon />
                <div>{info}</div>
              </div>
            )}
          </div>

          {!disableSize && (
            <div className={styles.group}>
              <Select
                label={staticText.size}
                onChange={(e) => setLocalSize(e.target.value)}
                options={sizeOptions}
                value={localSize}
              />
            </div>
          )}

          <div className={styles.group}>
            <Input label="Alt text" onChange={(e) => setLocalAlt(e.target.value)} type="text" value={localAlt} />
          </div>

          {!disableCaption && (
            <div className={styles.group}>
              <Input
                label="Caption"
                onChange={(e) => setLocalCaption(e.target.value)}
                type="text"
                value={localCaption}
              />
            </div>
          )}

          <Button icon={<SaveIcon />} onClick={onSaveClick}>
            Opslaan
          </Button>
        </>
      )}
    </Overlay>
  )
}
