import { AUTO, S, M, L, FULL } from 'shared/sizes'
import { SrcSet } from 'components/SrcSet'
import { useAppContext } from 'shared/AppContext'
import cx from 'classnames'
import styles from './Image.module.css'

export const Image = ({ content, maxWidth }) => {
  const { alt, caption, name, size, src, url } = content || {}
  const { imgixUrl } = useAppContext()

  const sizeMap = { [AUTO]: 720, [S]: 720, [M]: 960, [L]: 1280, [FULL]: 1560 }
  const maxImageWidth = maxWidth || sizeMap[size] || 5000

  const imageEl =
    name && size !== AUTO ? (
      <SrcSet alt={alt} className={styles.image} image={name} imgixUrl={imgixUrl} maxSize={maxImageWidth} />
    ) : (
      <img alt={alt} className={cx(styles.image, { [styles.autoWidth]: size === AUTO })} src={src || url} />
    )

  if (!caption) {
    return imageEl
  }

  return (
    <figure className={styles.figure}>
      {imageEl}
      <figcaption className={styles.caption}>{caption}</figcaption>
    </figure>
  )
}
