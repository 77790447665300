import { ChevronIcon } from 'components/Icons'
import { InputContainer } from 'components/InputContainer'
import cx from 'classnames'
import styles from './Select.module.css'

export const Select = ({ className, label, value, onChange, options, ...props }) => {
  const selectEl = (
    <div className={styles.selectContainer}>
      <select className={cx(styles.select, className)} onChange={onChange} value={value} {...props}>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <ChevronIcon />
    </div>
  )

  if (label) {
    return <InputContainer label={label}>{selectEl}</InputContainer>
  }

  return selectEl
}
