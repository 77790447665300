import {
  AddIcon,
  DeleteIcon,
  ImageIcon,
  FileIcon,
  ViewIcon,
} from "components/Icons";
import { Button } from "components/Button";
import { captureException } from "shared/sentry";
import { DeleteFileOverlay } from "components/DeleteFileOverlay";
import { Loading } from "components/Loading";
import { staticText } from "content/staticText";
import { useAppContext } from "shared/AppContext";
import { useFirestore, uploadFile, deleteFile } from "shared/firebase";
import { useState } from "react";
import cx from "classnames";
import styles from "./MediaLibrary.module.css";

export const MediaLibrary = ({
  filterFn,
  onUse,
  isFull,
  showView,
  showTrash,
}) => {
  const { firebaseInstances } = useAppContext();

  const { data, loading } = useFirestore({
    database: firebaseInstances.database,
    path: "/files",
    isCollection: true,
  });

  const [progress, setProgress] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [highlight, setHighlight] = useState(false);

  if (loading) {
    return <Loading />;
  }

  const totalProgress = progress
    ? Object.values(progress).reduce((acc, cur) => acc + cur) /
      Object.values(progress).length
    : 0;

  return (
    <div
      className={styles.container}
      onDragLeave={onUnhighlight}
      onDragOver={onHighlight}
      onDrop={onDrop}
    >
      <div className={styles.header}>
        <UploadButton onInputChange={onInputChange} />
        <MediaSearch filterText={filterText} setFilterText={setFilterText} />
      </div>
      <MediaGrid
        data={data}
        filterFn={filterFn}
        filterText={filterText}
        isFull={isFull}
        onUse={onUse}
        showTrash={showTrash}
        showView={showView}
      />
      {highlight && (
        <div className={styles.highlight}>{staticText.uploadFiles}</div>
      )}
      {totalProgress > 0 && (
        <div
          className={styles.progress}
          style={{ width: `${totalProgress}%` }}
        />
      )}
    </div>
  );

  function onInputChange(e) {
    if (!e.target.files.length) {
      return;
    }

    const files = Array.from(e.target.files);
    uploadFiles(files);
  }

  function uploadFiles(files) {
    const onComplete = () => {
      setProgress((x) => {
        const allProgressDone = Object.values(x || {}).every((p) => p === 100);
        return allProgressDone ? null : x;
      });
    };

    files.forEach((file) => {
      setProgress((x) => ({ [file.name]: 0 }));
      const onProgress = (progress) =>
        setProgress((x) => ({ ...x, [file.name]: progress }));

      uploadFile({
        database: firebaseInstances.database,
        file,
        fileName: file.name,
        onComplete,
        onError: captureException,
        onProgress,
        storage: firebaseInstances.storage,
      });
    });
  }

  function onHighlight(e) {
    e.preventDefault();
    setHighlight(true);
  }

  function onUnhighlight(e) {
    e.preventDefault();
    setHighlight(false);
  }

  function onDrop(e) {
    e.preventDefault();
    setHighlight(false);

    if (!e.dataTransfer.files.length) {
      return;
    }

    const files = Array.from(e.dataTransfer.files);
    uploadFiles(files);
  }
};

const MediaSearch = ({ filterText, setFilterText }) => (
  <input
    className={styles.searchInput}
    onChange={(e) => setFilterText(e.target.value)}
    placeholder={staticText.search}
    type="text"
    value={filterText}
  />
);

const MediaGrid = ({
  data,
  filterFn,
  filterText,
  isFull,
  onUse,
  showView,
  showTrash,
}) => {
  const files = Object.values(data || {})
    .filter(filterFn ? filterFn : () => true)
    .sort((a, b) => b.lastUpdate - a.lastUpdate)
    .filter(
      ({ name }) => name.toUpperCase().indexOf(filterText.toUpperCase()) > -1
    );

  return (
    <div className={cx(styles.grid, { [styles.isFull]: isFull })}>
      {files.map((file) => {
        const {
          id,
          ...fileData
        } = file; /* Remove id from file to prevent id clashing */
        return (
          <MediaPreview
            file={file}
            key={file.url}
            onUse={onUse ? () => onUse(fileData) : null}
            showView={showView}
            showTrash={showTrash}
          />
        );
      })}
    </div>
  );
};

const MediaPreview = ({ file, onUse, showView, showTrash }) => {
  const { firebaseInstances } = useAppContext();
  const [showTrashOverlay, setShowTrashOverlay] = useState(false);

  const buttons = [
    {
      onClick: onUse,
      title: staticText.use,
      icon: <AddIcon />,
      isVisible: !!onUse,
    },
    {
      onClick: () => window.open(file.url, "_blank"),
      title: staticText.watch,
      icon: <ViewIcon />,
      isVisible: showView,
    },
    {
      onClick: () => setShowTrashOverlay(true),
      title: staticText.delete,
      icon: <DeleteIcon />,
      isVisible: showTrash,
    },
  ].filter(({ isVisible }) => isVisible);

  return (
    <>
      <div className={styles.mediaPreview}>
        <div className={styles.mediaPreviewImageContainer}>
          {file.contentType.indexOf("image") > -1 ? (
            <img src={file.url} alt={file.name} />
          ) : (
            <FileIcon />
          )}

          <div className={styles.mediaButtonsContainer}>
            {buttons.map(({ onClick, title, icon }) => (
              <MediaPreviewButton
                key={title}
                onClick={onClick}
                title={title}
                icon={icon}
              />
            ))}
          </div>
        </div>
        <div className={styles.mediaPreviewLabel}>{file.name}</div>
      </div>
      {showTrashOverlay && (
        <DeleteFileOverlay
          onTrash={() => onTrash(file)}
          onClose={() => setShowTrashOverlay(false)}
        />
      )}
    </>
  );

  function onTrash({ fullPath, id }) {
    return deleteFile({
      storage: firebaseInstances.storage,
      database: firebaseInstances.database,
      fullPath,
      id,
      onError: captureException,
    });
  }
};

const MediaPreviewButton = ({ onClick, title, icon }) => (
  <button className={styles.mediaButton} onClick={onClick} title={title}>
    <span className={styles.mediaButtonInner}>{icon}</span>
  </button>
);

const UploadButton = ({ onInputChange }) => (
  <label>
    <Button as="div" icon={<ImageIcon />}>
      {staticText.uploadFiles}
    </Button>
    <input
      accept="image/*, video/*, audio/*, .pdf, .zip, .doc, .docx, .xlsx, .sketch"
      className={styles.uploadButtonInput}
      multiple
      onChange={onInputChange}
      type="file"
    />
  </label>
);
