import { onBlurCurrentTarget } from 'shared/onBlurCurrentTarget'
import { SketchPicker } from 'react-color'
import { useState } from 'react'
import styles from './ColorPicker.module.css'

export const ColorPicker = ({ children, onChange, value }) => {
  const [showColorPicker, setShowColorPicker] = useState(false)

  return (
    <div
      className={styles.colorContainer}
      onFocus={() => setShowColorPicker(true)}
      onBlur={(e) => onBlurCurrentTarget(e, () => setShowColorPicker(false))}
      tabIndex="1"
    >
      {children}
      <div className={styles.colorPickerContainer}>
        {showColorPicker && (
          <SketchPicker color={value} disableAlpha onChangeComplete={({ hex }) => onChange(hex)} presetColors={[]} />
        )}
      </div>
    </div>
  )
}
