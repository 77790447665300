import { createImgixUrl } from "shared/imgix";
import { Fragment } from "react";

const defaultSizes = [
  { min: 1900, width: 2000 },
  { min: 1800, width: 1900 },
  { min: 1700, width: 1800 },
  { min: 1600, width: 1700 },
  { min: 1500, width: 1600 },
  { min: 1400, width: 1500 },
  { min: 1300, width: 1400 },
  { min: 1200, width: 1300 },
  { min: 1100, width: 1200 },
  { min: 1000, width: 1100 },
  { min: 900, width: 1000 },
  { min: 800, width: 900 },
  { min: 700, width: 800 },
  { min: 600, width: 800 },
  { min: 500, width: 600 },
  { min: 400, width: 600 },
  { min: 300, width: 400 },
  { min: 200, width: 300 },
  { min: 1, width: 200 },
];

export const SrcSet = ({
  alt,
  className,
  imgixUrl,
  image,
  maxSize = 2000,
  sizes = defaultSizes,
}) => (
  <picture>
    {sizes
      .filter(({ min }) => min < maxSize)
      .map(({ min, width }, index) => {
        const root = imgixUrl;
        const url2x = createImgixUrl({ root, image, width, dpr: 2 });
        const url1x = createImgixUrl({ root, image, width, dpr: 1 });

        return (
          <Fragment key={`${url2x}-${index}`}>
            <source
              srcSet={`${url2x} ${width}w`}
              sizes={`(min-width: ${min}px) ${width}px`}
              media={`(min-width: ${min}px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: ${min}px) and (min-resolution: 120dpi)`}
            />
            <source
              srcSet={`${url1x} ${width}w`}
              sizes={`(min-width: ${min}px) ${width}px`}
              media={`(min-width: ${min}px)`}
            />
          </Fragment>
        );
      })}
    <img className={className} src="" alt={alt} />
  </picture>
);
